
import React, { useEffect, useState } from "react";
import Axios from "../../axios/axiosInstance";
import { formatDate } from "../../utils/dateConverter";
import useDivLoader from "../../components/loaders/useDivLoader";
import { useAppContext } from "../../context/SharedData";
import { useLocation } from "react-router-dom";
import { CustomDrawer } from "../../components/common/CustomDrawer";
import { PermitDetails } from "./PermitDetails";
import MyPagination from "../../components/Pagination/MyPagination";


const PermitList = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const status = queryParams.get("status") || ""
  const [openDrawer, setOpenDrawer] = useState(false)
  const [permitData, setPermitData] = useState()
  const [permitId, setPermitId] = useState("")
  const { setPageRefresh, pageRefresh } = useAppContext();
  const { showLoader, hideLoader, Loader, isLoading } = useDivLoader();
  const [currentPage, setCurrentPage] = useState(1)

  useEffect(() => {
    getPermitLists()
  }, [pageRefresh,status , currentPage])

  const getPermitLists = () => {
    showLoader()
    Axios.get(`/permits?page=${currentPage}&limit=${10}&status=${status == "issued" ? "Issued" : "Ready for Intake"}`).then((res) => {
      setPermitData(res.data)
    }).catch((err) => {
      alert("error", err)
    })
      .finally(() => {
        hideLoader()
        setPageRefresh(false)
      })
  }
  console.log(permitData)

  const onCloseDrawer = () => setOpenDrawer(false)
  return (
    <>
      <div className="d-flex align-items-center justify-content-between">
        <h4 className="onbord_heading boldtxt_on">Construction Permit List - {status == "issued" ? "Issued" : "Ready for Intake"}</h4>
      </div>
      <div className="whitebox-container mt-4">
        <div className={`tbwrp  mt-3 `}>
          <div className="page d-flex align-items-center text-nowrap d-xl-none">
          </div>
          <table>
            <thead>
              <tr>
                <th width="12%">Serial Number</th>
                <th width="15%">Permit Number</th>
                <th width="15%">Permit Type</th>
                <th width="15%">Status</th>
                <th width="10%">Expiration</th>
                <th width="20%">Description</th>
                <th className="action">Action</th>
              </tr>
            </thead>
            <tbody className="ldr">
              {permitData?.data?.length > 0 && permitData?.data?.map((ele, index) => {
                return <tr key={index}>
                  <td>{currentPage === 1 ? index + 1 : currentPage * 10 + (index + 1) - 10}</td>
                  <td>{ele?.permitnumber}</td>
                  <td>{ele?.permittype}</td>
                  <td>{ele?.status}</td>
                  <td>{ele?.expiration ? formatDate(ele.expiration) : "N/A"}</td>
                  <td><div className="enp2">{ele?.description}</div></td>
                  <td><button className="border-0 bg-transparent"> <img onClick={() => {
                    setOpenDrawer(true)
                    setPermitId(ele.id)
                  }} src="img/eye.svg" alt="rejected" className="eyesw" /></button></td>
                </tr>
              })}
              <Loader type={"tableLines"} />
            </tbody>
          </table>
            {!isLoading && permitData?.data?.length === 0 && (
              <p className="mt-4" style={{ textAlign: "center" }}>
                No data found
              </p>
            )}

        </div>
        <div className="d-flex align-items-center justify-content-between mt-4">
          <div className="page d-xl-flex align-items-center text-nowrap d-none">
            {permitData?.totalRecords > 0 && (
              <span>
                Showing {permitData?.totalRecords} {permitData?.totalRecords === 1 ? 'entry' : 'entries'}
              </span>
            )}
          </div>
          {!isLoading && permitData?.data?.length > 0 && (
            <div className="paggtbl ms-auto">
              <MyPagination
                key={currentPage}
                onChange={(e) => setCurrentPage(e)}
                current={currentPage} s
                total={permitData?.totalRecords}
                limit={10}
              />
            </div>
          )}
        </div>
      </div>
      <CustomDrawer open={openDrawer} onClose={onCloseDrawer} body={<PermitDetails onClose={onCloseDrawer} permitId={permitId} />} />
    </>
  );
};

export default PermitList;

