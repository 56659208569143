import "./App.scss";
import React, { Suspense, lazy } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { createHashRouter, RouterProvider } from "react-router-dom";
import DashboardLayout from "./components/layout/DashboardLayout";
import OnboardingLayout from "./components/layout/OnboardingLayout";
import Login from "./pages/auth/Login";
import { AppProvider } from "./context/SharedData";
import FullScreenLoader from "./components/loaders/FullScreenLoader";
import { UserProfile } from "./pages/profile/UserProfile";
import ForgotPassword from "./pages/auth/signup/ForgotPassword";
import VerificationCode from "./pages/auth/signup/VerificationCode";
import ResetPassword from "./pages/auth/signup/ResetPassword";
import ChangePassword from "./pages/auth/signup/ChangePassword";
import ConstructionPermit from "./pages/construction permits/ConstructionPermit";
import LicenseList from "./pages/License/LicenseList";
import PermitList from "./pages/construction permits/PermitList";
const Users = lazy(() => import("./pages/users/Users"));


function App() {
  const router = createHashRouter([
    {
      path: "/",
      element: <OnboardingLayout />,
      children: [
        {
          path: "/",
          element: <Login />,
        },
        {
          path: "/forgot-password",
          element: <ForgotPassword />,
        },
        {
          path: "/reset-password",
          element: <ResetPassword />,
        },
        {
          path: "/otp",
          element: <VerificationCode />,
        },

      ],
    },
    {
      path: "/",
      element: <DashboardLayout />,
      children: [
        {
          path: "/users",
          element: <Users />,
        },
        {
          path: "/license",
          element: <LicenseList />,
        },
        {
          path: "/user-profile",
          element: <UserProfile />
        },
        {
          path: "/change-password",
          element: <ChangePassword />
        },
        {
          path: "/construction-permit",
          element: <ConstructionPermit />
        },
        {
          path: "/construction-permit/permit-list",
          element: <PermitList />
        }

      ],
    },
  ]);
  return (
    <Suspense fallback={<FullScreenLoader />}>
      <AppProvider>
        <RouterProvider router={router} />
      </AppProvider>
    </Suspense>
  );
}

export default App;
