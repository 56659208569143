

import React, { useEffect, useState, useRef } from "react";
import { Link, useNavigate } from "react-router-dom";
import Axios from "../../../axios/axiosInstance";
import alert from "../../../utils/alert";
import OtpInput from "react-otp-input";
import { useAppContext } from "../../../context/SharedData";
import useLoader from "../../../components/loaders/useLoader";

const VerificationCode = () => {
    const [otp, setOtp] = useState("");
    const [timer, setTimer] = useState(60);
    const navigate = useNavigate()
    const { sharedData } = useAppContext()
    const { showLoader, hideLoader, Loader } = useLoader()

    useEffect(() => {
        let interval;
        if (timer > 0) {
            interval = setInterval(() => {
                setTimer(timer - 1);
            }, 1000);
        } else {
            clearInterval(interval);
        }
        return () => clearInterval(interval);
    }, [timer]);

    const handleVerify = (e) => {
        e.preventDefault();
        showLoader();

        if (otp.length !== 6) {
            alert("error", "Invalid otp")
            hideLoader()
            return
        }
        Axios.post("verify-otp", {
            otp: otp,
            email: sharedData?.emailForOtpVerify,
        })
            .then((res) => {
                if (res) {
                    localStorage.setItem("user_token", res.data.token);
                    alert("success", "OTP verified successfully");
                    navigate("/reset-password", { state: { otp: otp } });
                }
            })
            .catch((err) => alert("error", err?.response?.data?.error))
            .finally(() => {
                hideLoader();
            });
    };

    const onClickResendOTP = () => {
        showLoader();
        Axios.post("forgot-password", {
            email: sharedData?.emailForOtpVerify,
        })
            .then((res) => {
                if (res) {
                    setTimer(60);
                    setOtp("")
                    alert("success", "OTP has been sent to your email.");
                }
            })
            .catch((err) => alert("error", err?.response?.data?.message))
            .finally(() => {
                hideLoader();
            });
    };

    //Restrict browser to reload
    const isMounted = useRef(false); // Create a ref to track mount status

    useEffect(() => {
        if (!isMounted.current) {
            isMounted.current = true; // Mark the component as mounted
            if (!sharedData.emailForOtpVerify) {
                navigate(-1);
                alert("error", "Session expired");
            }
        }
    }, []);

    const handleBeforeUnload = (event) => {
        // Show a generic confirmation message when the user tries to reload the browser
        const confirmationMessage = "You have unsaved changes. Are you sure you want to leave?";
        event.returnValue = confirmationMessage;
        return confirmationMessage;
    };

    useEffect(() => {
        //for page reload case
        window.addEventListener("beforeunload", handleBeforeUnload);

        return () => {
            window.removeEventListener("beforeunload", handleBeforeUnload);
        };
    }, []);

    return (
        <>
            <h3 className="boldtxt_on text-center">Verification Code</h3>

            <form onSubmit={handleVerify}>
                <label htmlFor="email" className="d-block">
                    Enter Code
                </label>
                <div className="min_hform mt-1">
                    <OtpInput
                        value={otp}
                        onChange={setOtp}
                        numInputs={6}
                        containerStyle="otp-container"
                        renderInput={(props) => (
                            <input {...props} className="otp-itm mt-6" placeholder="0" />
                        )}
                        isInputNum={true} // Only allow numeric input
                        separator={<span>-</span>} // Optional separator between input fields
                        inputStyle="fill_input mt-6" // CSS class for individual OTP input fields
                    />
                    {/* {errors.email && <span className="text-danger">Please enter a valid email address</span>} */}
                    <div className="text-end">
                        {timer <= 0 &&
                            <button onClick={(e) => {
                                e.preventDefault()
                                onClickResendOTP()
                            }} className="fz14 bg-transparent border-0 black ms-auto max-fit mt-2">Resend Code</button>}
                    </div>
                    <div className="text-center mt-4">
                        {timer > 0 && <p className="">Resend OTP in {timer} seconds</p>}
                    </div>
                </div>

                <div className="text-center">
                    {/* {timer > 0 && ( */}
                    <button disabled={timer <= 0 ? true : false} id="b" type="submit" className="btn_cus">
                        SEND
                    </button>
                    {/* )} */}
                </div>

            </form>
            {/* {timer <= 0 && (
                <button onClick={() => onClickResendOTP()} id="b" className="btn_cus">
                    Resend OTP
                </button>
            )} */}



        </>
    );
};

export default VerificationCode;
