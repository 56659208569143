// import React, { useEffect, useState } from "react";
// import Axios from "../../axios/axiosInstance";
// import useDivLoader from "../../components/loaders/useDivLoader";

// export const PermitDetails = ({ onClose, permitId }) => {
//   const { showLoader, hideLoader, Loader } = useDivLoader();
//   const [details, setDetails] = useState();

//   useEffect(() => {
//     permitId && getData();
//   }, [permitId]);

//   const getData = () => {
//     showLoader();
//     Axios.get("permits/" + permitId)
//       .then((res) => {
//         setDetails(res.data.data);

//       })
//       .catch((err) => {
//         err.response && alert("error", err.response.data.error);
//       })
//       .finally(() => {
//         hideLoader();
//       });
//   };

//   console.log("e", details)

//   return (
//     <>
//       <div className="modal_header mb-4 ldr">
//         <div className="d-flex align-items-center">
//           <span className="me-2 pointer">
//             <img onClick={onClose} src="img/arrow_back.svg" alt="arrow back" />
//           </span>
//           <span>Profile</span>
//         </div>
//       </div>
//       {/* <div className="profile_img  text-center mb-5">
//         <img src="img/profile.png" alt="profile" className="profile_iconV" />
//       </div> */}
//       <div className="form_output">
//         <div className="input_grp">
//           <div className="input_itm w-100">
//             <h2 className="">Permit Number:</h2>
//             <span className="out_txt">{details?.permitnumber}</span>
//           </div>
//         </div>
//         <div className="input_grp mt-4">
//           <div className="input_itm w-100">
//             <label className="d-block">Contact Name</label>
//             <div className="out_txt">{details?.primaryprincipalname}</div>
//           </div>
//         </div>
//         <div className="input_grp mt-4">
//           <div className="input_itm w-100">
//             <label className="d-block">Phone No.</label>
//             <div className="out_txt">{details?.phonenumber}</div>
//           </div>
//         </div>
//         <div className="input_grp mt-4">
//           <div className="input_itm w-100">
//             <label className="d-block">City</label>
//             <div className="out_txt">{details?.city}</div>
//           </div>
//         </div>
//         <div className="input_grp mt-4">
//           <div className="input_itm w-100">
//             <label className="d-block">State</label>
//             <div className="out_txt">{details?.state}</div>
//           </div>
//         </div>
//         <div className="input_grp mt-4">
//           <div className="input_itm w-100">
//             <label className="d-block">Zip</label>
//             <div className="out_txt">{details?.zip}</div>
//           </div>
//         </div>
//         <div className="input_grp mt-4">
//           <div className="input_itm w-100">
//             <label className="d-block">Lic. Type</label>
//             <div className="out_txt">{details?.contractorlicensetypecodedesc}</div>
//           </div>
//         </div>
//         <div className="input_grp mt-4">
//           <div className="input_itm w-100">
//             <label className="d-block">SP Code</label>
//             <div className="out_txt">{details?.specialtycode1}</div>
//           </div>
//         </div>
//         <div className="input_grp mt-4">
//           <div className="input_itm w-100">
//             <label className="d-block">SP Code Desc</label>
//             <div className="out_txt">{details?.specialtycode1desc}</div>
//           </div>
//         </div>
//       </div>
//       <Loader type={"tableLines"} />
//     </>
//   );
// };

import React, { useEffect, useState } from "react";
import Axios from "../../axios/axiosInstance";
import useDivLoader from "../../components/loaders/useDivLoader";

export const PermitDetails = ({ onClose, permitId }) => {
  const { showLoader, hideLoader, Loader } = useDivLoader();
  const [details, setDetails] = useState(null);

  useEffect(() => {
    if (permitId) {
      getData();
    }
  }, [permitId]);

  const getData = () => {
    showLoader();
    Axios.get("permits/" + permitId)
      .then((res) => {
        setDetails(res.data.data); // Assuming res.data contains the full permit details object
      })
      .catch((err) => {
        console.error("Error fetching permit details:", err);
        alert("Error fetching permit details");
      })
      .finally(() => {
        hideLoader();
      });
  };

  const formatNewlines = (text) => {
    if (!text) return '';
    return text.replace(/\n/g, '<br />');
  };

  return (
    <>
      <div className="modal_header mb-4 ldr">
        <div className="d-flex align-items-center">
          <span className="me-2 pointer">
            <img onClick={onClose} src="img/arrow_back.svg" alt="arrow back" />
          </span>
          <span>Profile</span>
        </div>
      </div>
      <div className="form_output">
        {details && (
          <>
            <div className="input_grp">
              <div className="input_itm w-100">
                <h4 className="fz20 DMSans_bold">Permit Number:<span>{details.permitnumber}</span></h4>
                <p className="txt mb-2 ">Permit Type:<span>{details.permittype}</span></p>
                <p className="txt  mb-2 ">Status: <span>{details.status}</span></p>
                <p className="txt ">Expiration Date: <span>{details.expiration || 'N/A'}</span></p>

              </div>
            </div>
            {/* <div className="input_grp mt-4">
              <div className="input_itm w-100">
                <label className="d-block">Permit Type:</label>
                <div className="out_txt">{details.permittype}</div>
              </div>
            </div> */}
            {/* <div className="input_grp mt-4">
              <div className="input_itm w-100">
                <label className="d-block">Status:</label>
                <div className="out_txt">{details.status}</div>
              </div>
            </div> */}
            <div className="input_grp mt-4">
              <div className="input_itm w-100">
                <h4 className="fz20 DMSans_bold">Description:</h4>
                <p
                  className="txt mb-0"
                  dangerouslySetInnerHTML={{ __html: formatNewlines(details.description) }}
                ></p>
              </div>
            </div>
            <div className="input_grp mt-4">
              <div className="input_itm w-100">
                <h4 className="fz20 DMSans_bold">Owner:</h4>
                <p
                  className="txt mb-0"
                  dangerouslySetInnerHTML={{ __html: formatNewlines(details.owner) }}
                ></p>
              </div>
            </div>
            <div className="input_grp mt-4">
              <div className="input_itm w-100">
                <h4 className="fz20 DMSans_bold">Contractor:</h4>
                <p
                  className="txt mb-0"
                  dangerouslySetInnerHTML={{ __html: formatNewlines(details.contractor) }}
                ></p>
              </div>
            </div>
            {/* <div className="input_grp mt-4">
              <div className="input_itm w-100">
                <label className="d-block">Expiration Date:</label>
                <div className="out_txt">{details.expiration || 'N/A'}</div>
              </div>
            </div> */}
            <div className="input_grp mt-4">
              <div className="input_itm w-100">
                <h4 className="fz20 DMSans_bold">Permit Application Common:</h4>
                <p
                  className="txt mb-0"
                  dangerouslySetInnerHTML={{ __html: formatNewlines(details.permit_application_common) }}
                ></p>
              </div>
            </div>
            <div className="input_grp mt-4">
              <div className="input_itm w-100">
                <h4 className="fz20 DMSans_bold">Permit Tracking Common:</h4>
                <p
                  className="txt mb-0"
                  dangerouslySetInnerHTML={{ __html: formatNewlines(details.permit_tracking_common) }}
                ></p>
              </div>
            </div>
            <div className="input_grp mt-4">
              <div className="input_itm w-100">
                <h4 className="fz20 DMSans_bold">Floating Residence Info:</h4>
                <p
                  className="txt mb-0"
                  dangerouslySetInnerHTML={{ __html: formatNewlines(details.floating_residence_info) }}
                ></p>
              </div>
            </div>
            <div className="input_grp mt-4">
              <div className="input_itm w-100">
                <h4 className="fz20 DMSans_bold">Construction Valuation Info:</h4>
                <p
                  className="txt mb-0"
                  dangerouslySetInnerHTML={{ __html: formatNewlines(details.construction_valuation_info) }}
                ></p>
              </div>
            </div>
            <div className="input_grp mt-4">
              <div className="input_itm w-100">
                <h4 className="fz20 DMSans_bold">Building Code Info:</h4>
                <p
                  className="txt mb-0"
                  dangerouslySetInnerHTML={{ __html: formatNewlines(details.building_code_info) }}
                ></p>
              </div>
            </div>
            <div className="input_grp mt-4">
              <div className="input_itm w-100">
                <h4 className="fz20 DMSans_bold">Ground Disturbance:</h4>
                <p
                  className="txt mb-0"
                  dangerouslySetInnerHTML={{ __html: formatNewlines(details.ground_disturbance) }}
                ></p>
              </div>
            </div>
            <div className="input_grp mt-4">
              <div className="input_itm w-100">
                <h4 className="fz20 DMSans_bold">Tenant Relocation Assistance:</h4>
                <p className="txt mb-0">{details.tenant_relocation_assistance || 'N/A'}</p>
              </div>
            </div>
            <div className="input_grp mt-4">
              <div className="input_itm w-100">
                <h4 className="fz20 DMSans_bold">Contractor Disclosure:</h4>
                <p className="txt mb-0">{details.contractor_disclosure || 'N/A'}</p>
              </div>
            </div>
            <div className="input_grp mt-4">
              <div className="input_itm w-100">
                <h4 className="fz20 DMSans_bold">Application Info:</h4>
                <p
                  className="txt mb-0"
                  dangerouslySetInnerHTML={{ __html: formatNewlines(details.applicationinfo) }}
                ></p>
              </div>
            </div>
          </>
        )}
      </div>
      <Loader type={"tableLines"} />
    </>
  );
};




