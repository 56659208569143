

import React from "react";
import { Link, useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import Axios from "../../../axios/axiosInstance";
import alert from "../../../utils/alert";
import { useAppContext } from "../../../context/SharedData";

const ForgotPassword = () => {
    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm({ mode: "onChange" });

    const navigate = useNavigate();
    const { setSharedData } = useAppContext()

    const onSubmit = (data) => {

        Axios.post("forgot-password", {
            email: data.email
        })
            .then((res) => {
                if (res) {
                    setSharedData((prevData) => ({
                        ...prevData,
                        emailForOtpVerify: data.email
                    }));
                    navigate("/otp", { state: { email: data.email } })
                }
            })
            .catch((err) => err.response && alert("error", err.response.data.error));
    };

    return (
        <>
            <h3 className="boldtxt_on text-center">Forgot Password</h3>

            <form onSubmit={handleSubmit(onSubmit)}>
                <div className="min_hform">
                    <div className="input_grp mt30">
                        <div className="d-flex align-items-start">
                            <img src="img/email.svg" alt="email" className="icon_input me-2 mt-1" />
                            <div className="input_itm w-100">
                                <label htmlFor="email" className="d-block">
                                    Email Address<sup>*</sup>
                                </label>
                                <input
                                    type="email"
                                    id="email"
                                    className="inputFill border-0"
                                    placeholder="Enter Email Address"
                                    {...register("email", {
                                        required: "Email is required",
                                        pattern: {
                                            value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                                            message: "Please enter a valid email address",
                                        },
                                    })}
                                />
                            </div>
                        </div>
                    </div>
                    {errors.email && <span className="text-danger">Please enter a valid email address</span>}

                </div>
                <button type="submit" className="btn_cus">
                    SEND
                </button>
                {/* <p className="text-center mt-3">
                    Already have an account?{" "}
                    <Link to={`/`} className="black">
                        Log in
                    </Link>
                </p> */}
            </form>


        </>
    );
};

export default ForgotPassword;
