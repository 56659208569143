
import React, { useEffect, useState } from "react";
import Axios from "../../axios/axiosInstance";
import { formatDate } from "../../utils/dateConverter";
import useDivLoader from "../../components/loaders/useDivLoader";
import { useAppContext } from "../../context/SharedData";
import MyPagination from "../../components/Pagination/MyPagination";


const ConstructionPermit = () => {
    const [permitData, setPermitData] = useState()
    const { setPageRefresh, pageRefresh } = useAppContext();
    const { showLoader, hideLoader, Loader, isLoading } = useDivLoader();
    const [currentPage, setCurrentPage] = useState(1)

    useEffect(() => {
        getLicenseLists()
    }, [pageRefresh, currentPage])

    const getLicenseLists = () => {
        showLoader()
        Axios.get(`/permits/files?page=${currentPage}&limit=${10}`).then((res) => {
            setPermitData(res.data)
        }).catch((err) => {
            alert("error", err)
        })
            .finally(() => {
                hideLoader()
                setPageRefresh(false)
            })
    }

    return (
        <>
            <div className="d-flex align-items-center justify-content-between">
                <h4 className="onbord_heading boldtxt_on">Construction Permit</h4>
            </div>
            <div className="whitebox-container mt-4">
                <div className={`tbwrp  mt-3 `}>
                    <div className="page d-flex align-items-center text-nowrap d-xl-none">
                    </div>
                    <table>
                        <thead>
                            <tr>
                                <th width="12%">Serial Number</th>
                                <th width="15%"> Name</th>
                                <th width="15%">Status</th>
                                <th width="15%">Created At</th>

                            </tr>
                        </thead>
                        <tbody className="ldr">
                            {permitData?.data?.length > 0 && permitData?.data?.map((ele, index) => {
                                return <tr key={index}>
                                    <td>{currentPage === 1 ? index + 1 : currentPage * 10 + (index + 1) - 10}</td>
                                    <td>{ele.name}</td>
                                    <td>{ele.status === "completed" ? <span style={{ color: "green" }}>{ele.status}</span> : <span style={{ color: "orange" }}>{ele.status}</span>}</td>
                                    <td>{formatDate(ele.created_at, "date-time")}</td>
                                </tr>
                            })}

                            <Loader type={"tableLines"} />
                        </tbody>
                    
                    </table>
                    {!isLoading && permitData?.data?.length === 0 && (
                        <p className="mt-4" style={{ textAlign: "center" }}>
                            No data found
                        </p>
                    )}
                    <div className="d-flex align-items-center justify-content-between mt-4">
                        <div className="page d-xl-flex align-items-center text-nowrap d-none">
                            {permitData?.totalRecords > 0 && (
                                <span>
                                    Showing {permitData?.totalRecords} {permitData?.totalRecords === 1 ? 'entry' : 'entries'}
                                </span>
                            )}
                        </div>
                        {!isLoading && permitData?.data?.length > 0 && (
                            <div className="paggtbl ms-auto">
                                <MyPagination
                                    key={currentPage}
                                    onChange={(e) => setCurrentPage(e)}
                                    current={currentPage} s
                                    total={permitData?.totalRecords}
                                    limit={10}
                                />
                            </div>
                        )}
                    </div>
                </div>
            </div></>
    );
};

export default ConstructionPermit;

