import Axios from "../axios/axiosInstance";
import alert from "../utils/alert";


export const fileUpload = async (file, type = "file", url) => {
  

  const formData = new FormData()
  formData.append(type, file)

  await Axios.post(url + "/upload", formData, {
    headers: {
      "Content-Type": "multipart/form-data",
    }
  }).then((res) => {
    alert("success", res.data.message)
  })
    .catch((err) => {
      err.response && alert("error", err.response.data.error);
    })
    .finally(() => {
      // hideLoader();
    });

}