

import React, { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import Axios from "../../../axios/axiosInstance";
import alert from "../../../utils/alert";
import useLoader from "../../../components/loaders/useLoader";
import { useAppContext } from "../../../context/SharedData";
const ResetPassword = () => {
    const {
        register,
        handleSubmit,
        watch,
        formState: { errors },
    } = useForm();
    const { showLoader, hideLoader, Loader } = useLoader()

    const navigate = useNavigate();
    const location = useLocation();
    const { sharedData } = useAppContext()

    const [togglePassword, setTogglePassword] = useState(false);
    const [toggleCPassword, setToggleCPassword] = useState(false);


    const onSubmit = (data) => {
        showLoader()
        Axios.post("reset-password", {
            email: sharedData.emailForOtpVerify,
            otp: location?.state?.otp,
            password: data.password,
        })
            .then((res) => {
                if (res) {
                    alert("success", "Password changed successfully");
                    navigate("/");
                }
            })
            .catch((err) => alert("error", err?.response?.data?.message))
            .finally(() => {
                hideLoader()
            });
    };

    //Restrict browser to reload
    const isMounted = useRef(false); // Create a ref to track mount status

    useEffect(() => {
        if (!isMounted.current) {
            isMounted.current = true; // Mark the component as mounted
            if (!sharedData.emailForOtpVerify) {
                navigate("/");
                alert("error", "Session expired");
            }
        }
    }, []);

    const handleBeforeUnload = (event) => {
        // Show a generic confirmation message when the user tries to reload the browser
        const confirmationMessage = "You have unsaved changes. Are you sure you want to leave?";
        event.returnValue = confirmationMessage;
        return confirmationMessage;
    };

    useEffect(() => {
        //for page reload case
        window.addEventListener("beforeunload", handleBeforeUnload);

        return () => {
            window.removeEventListener("beforeunload", handleBeforeUnload);
        };
    }, []);

    return (
        <>
            <h3 className="boldtxt_on text-center">Reset Password</h3>
            <form onSubmit={handleSubmit(onSubmit)}>
                <div className="input_grp mt30">
                    <div className="d-flex align-items-start">
                        <img src="img/password.svg" alt="password" className="icon_input me-2 mt-1" />
                        <div className="input_itm w-100">
                            <label htmlFor="password" className="d-block">
                                New Password
                            </label>
                            <div className="position-relative">
                                <input
                                    type={togglePassword ? "" : "password"}
                                    placeholder="Enter new password"
                                    className="inputFill border-0"
                                    {...register("password", {
                                        required: "Password is required",
                                        minLength: { value: 8, message: "Password must be at least 8 characters long" },
                                    })}
                                />
                                {togglePassword ? (
                                    <img
                                        onClick={() => setTogglePassword(!togglePassword)}
                                        src="img/eye.svg"
                                        alt="eye"
                                        className="position-absolute  pwd_icon"
                                    />
                                ) : (
                                    <img
                                        onClick={() => setTogglePassword(!togglePassword)}
                                        src="img/eyeClose.svg"
                                        alt="eye"
                                        className="position-absolute  pwd_icon"
                                    />
                                )}
                            </div>

                        </div>
                    </div>
                    {errors.password && <span className="text-danger">{errors.password.message}</span>}
                </div>
                <div className="input_grp mt30">
                    <div className="d-flex align-items-start">
                        <img src="img/password.svg" alt="password" className="icon_input me-2 mt-1" />
                        <div className="input_itm w-100">
                            <label htmlFor="password" className="d-block">
                                Confirm Password
                            </label>
                            <div className="position-relative">
                                <input
                                    type={toggleCPassword ? "" : "password"}
                                    placeholder="Enter confirm password"
                                    className="inputFill border-0"
                                    {...register("confirmPassword", {
                                        validate: (value) => value === watch("password") || "Passwords do not match",
                                    })}
                                />
                                {toggleCPassword ? (
                                    <img
                                        onClick={() => setToggleCPassword(!toggleCPassword)}
                                        src="img/eye.svg"
                                        alt="eye"
                                        className="position-absolute  pwd_icon"
                                    />
                                ) : (
                                    <img
                                        onClick={() => setToggleCPassword(!toggleCPassword)}
                                        src="img/eyeClose.svg"
                                        alt="eye"
                                        className="position-absolute  pwd_icon"
                                    />
                                )}
                            </div>

                        </div>
                    </div>
                    {errors.confirmPassword && (
                        <span className="text-danger">{errors.confirmPassword.message}</span>
                    )}
                </div>

                <div className="text-center mt-5">
                    <button id="b" type="submit" className="btn_cus">
                        PROCEED
                    </button>
                </div>
                {/* <Link to="/" className="backLink mt-4 d-block text-center">
                    Back to Login
                </Link> */}
            </form>
        </>
    );
};

export default ResetPassword;
