import React from "react";
import { Outlet } from "react-router-dom";

const OnboardingLayout = () => {
  return (
    <>
      <div className="onboard_ctr w-100 h-100  d-flex align-items-center justify-content-center">
        <div className="onboard_wrp">
          <h1 className="text-center">
            <img src="img/logo.svg" alt="logo" /></h1>

          <Outlet />
        </div>
      </div>

    </>
  );
};
export default OnboardingLayout;
