import React, { useEffect, useState } from "react";
import { Outlet } from "react-router-dom";
import Header from "./Header";
import Sidebar from "./Sidebar";
import { useLocation } from "react-router-dom";
const DashboardLayout = () => {

  const location = useLocation();

  return (
    <>
      <div className="dashboard">
        <div className="d-flex">
          <aside>
            <Sidebar />
          </aside>
          <div className="dashboard_wrp">
            <Header />
            <div className={location.pathname !== "/map" ? "dash_content" : "dash_map"}>
              <Outlet />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default DashboardLayout;
