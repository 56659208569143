import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { useAppContext } from "../../context/SharedData";
import { fileUpload } from "../../api/uploadFile";
import alert from "../../utils/alert";

const CustomModal = ({
  modalData,
  action,
  handleChange,
  show,
  onHide,
  handleClose,
  modalBodyText,
}) => {

  const { setPageRefresh, pageRefresh } = useAppContext();
  let modalContent;
  const [files, setFiles] = useState()


  useEffect(() => {
    show && setFiles(null)
  }, [show])
  console.log(files?.name)
  // eslint-disable-next-line default-case
  switch (modalData.typeOfModal) {
    case "delete":
      modalContent = (
        <Modal centered show={show} onHide={onHide}>
          <Modal.Header closeButton className="border-0"></Modal.Header>
          <div className="modal_body">
            <div className="text-center">
              {" "}
              <img src="img/modal_delete.svg" alt="modal delete icon" />
            </div>
            <h3 className="modal_ttl text-center py-3 mb-0">Delete!</h3>
            <p className="text-center mdltxt graytxt mb-0 px-sm-5 px-2">
              Are you sure you want to Delete this post?
            </p>
            <div className="footer_modal py-3 mt-2 d-flex justify-content-center">
              <button onClick={() => handleChange()} className="btn_cus btnMinW me-3 py-2">
                OK
              </button>
              <button onClick={() => handleClose()} className="btn_cus br_white_btn btnMinW py-2">
                No
              </button>
            </div>
          </div>
        </Modal>
      );
      break;

    case "upload":
      modalContent = (
        <Modal centered show={show} onHide={onHide}>
          <Modal.Header closeButton className="border-0 ">
            <span className="modal_ttl">Upload Files</span></Modal.Header>
          <div className="modal_body">
            {!files ?
              <div className="border mx-3 rounded p-3 text-center">
                <div className="text-dark mb-2">Select File Here</div>
                <p className="fz14">Files Supported: CSV</p>
                <label htmlFor="uploadfile" className="uploadfile text-center DMSans_med">
                  <input accept=".csv" onChange={(e) => setFiles(e.target.files[0])} type="file" id="uploadfile" className="d-none" />
                  Choose File
                </label>
              </div> :
              <div className="border mx-3 rounded p-3 py-5 text-center">
                <div className="uploadFileview">
                  <img src="img/pdf-img.svg" alt="" className="uploadFileview" />
                  <button onClick={() => setFiles(null)} className="border-0 padding-0 bg-transparent delete-iconbtn">
                    <img src="img/delete_img.svg" alt="delete_img" />
                  </button>
                </div>
                <div className="file-upld-ext fz14 mt-3 Outfit_reg">{files && files?.name}</div>
              </div>
            }
            <div className="footer_modal py-3 mt-2 d-flex justify-content-center">
              <button onClick={() => {
                if (files) {
                  fileUpload(files, "file", modalData.reportType).then((res) => {
                    onHide()
                    setPageRefresh(!pageRefresh)
                  })
                } else {
                  alert("error", "Please select file")
                }
              }
              } className="btn_cus w-100" disabled={show?false:true}>
                UPLOAD
              </button>
            </div>
          </div>
        </Modal>
      );
      break;

    case "signup":
      modalContent = (
        <Modal centered show={show} onHide={onHide}>
          <Modal.Header closeButton className="border-0"></Modal.Header>
          <div className="modal_body p-3 pt-0">
            <div className="text-center">
              {modalBodyText?.img &&
                <img src={modalBodyText?.img} alt="modal alert icon" className="mb-3" />
              }
            </div>
            <h3 className="modal_ttl text-center mb-0">{modalBodyText?.header1}</h3>
            <h4 className="modal_sub_ttle text-center mb-0 mt-4 ">{modalBodyText?.header2}</h4>
            <p className="text-center fz14 mdltxt graytxt mb-0">
              {modalBodyText?.body1}
            </p>

            <p className="text-center fz14 graytxt mt-2 mb-0">
              {modalBodyText?.body2}
            </p>
            <div className="footer_modal py-3 mt-2 d-flex justify-content-center">
              <button onClick={() => handleChange()} className="btn_cus btnMinW me-3 py-2">
                OK
              </button>
              {modalBodyText.header1 !== "Congratulations!" &&
                <button onClick={() => {
                  if (action) {
                    action()
                    return
                  }
                  handleClose()

                }} className="btn_cus br_white_btn btnMinW py-2">
                  No
                </button>}
            </div>
          </div>
        </Modal>
      );
      break;

  }

  return <>{modalContent}</>;
};

export default CustomModal;
