
import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import alert from "../../../utils/alert";
import Axios from "../../../axios/axiosInstance";


const ChangePassword = () => {
    const {
        register,
        handleSubmit,
        watch,
        reset,
        formState: { errors },
    } = useForm({
        oldPassword: "",
        password: "",
        confirmPassword: ""
    });

    const [togglePassword, setTogglePassword] = useState(false);
    const [toggleCPassword, setToggleCPassword] = useState(false);
    const [toggleOldPassword, setToggleOldPassword] = useState(false);

    const onSubmit = (data) => {
        if (data.password !== data.confirmPassword) {
            alert("error", "Passwords do not match");
            return;
        }

        Axios.put("change-password", {
            "old_password": data.oldPassword,
            "password": data.password,
            "confirm_password": data.confirmPassword
        }).then((res) => {
            console.log("res", res)
            localStorage.setItem("user_token", res.data.token)
            alert("success", res.data.message);
            reset()
        }).catch((err) => alert("error", err?.response?.data?.error));

    };

    return (
        <>
            <h4 className="onbord_heading boldtxt_on">Change Password</h4>
            {/* <p className="txt text-center mb-40 text-white">Enter new password here</p> */}
            <div className="whitebox-container mt-4">
                <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="row">
                        <div className="col-lg-4 col-md-6   ">
                            <div className="input_grp">
                                <div className="d-flex align-items-start">
                                    {/* <img src="img/password.svg" alt="password" className="icon_input me-2 mt-1" /> */}
                                    <div className="input_itm w-100">
                                        <label htmlFor="oldPassword" className="d-block">
                                            Old Password
                                        </label>
                                        <div className="position-relative">
                                            <input
                                                type={toggleOldPassword ? "" : "password"}
                                                placeholder="Enter old password"
                                                className="inputFill border-0"
                                                {...register("oldPassword", { required: "Old Password is required" })}
                                            />
                                            {toggleOldPassword ? (
                                                <img
                                                    onClick={() => setToggleOldPassword(!toggleOldPassword)}
                                                    src="img/eye.svg"
                                                    alt="eye"
                                                    className="position-absolute  pwd_icon"
                                                />
                                            ) : (
                                                <img
                                                    onClick={() => setToggleOldPassword(!toggleOldPassword)}
                                                    src="img/eyeClose.svg"
                                                    alt="eye"
                                                    className="position-absolute  pwd_icon"
                                                />
                                            )}
                                        </div>
                                        {errors.oldPassword && <span className="text-danger">{errors.oldPassword.message}</span>}
                                    </div>
                                </div>
                            </div>

                            <div className="input_grp mt30">
                                <div className="d-flex align-items-start">
                                    {/* <img src="img/password.svg" alt="password" className="icon_input me-2 mt-1" /> */}
                                    <div className="input_itm w-100">
                                        <label htmlFor="password" className="d-block">
                                            New Password
                                        </label>
                                        <div className="position-relative">
                                            <input
                                                type={togglePassword ? "" : "password"}
                                                placeholder="Enter new password"
                                                className="inputFill border-0"
                                                {...register("password", {
                                                    required: "New Password is required",
                                                    minLength: { value: 8, message: "Password must be at least 8 characters long" },
                                                })}
                                            />
                                            {togglePassword ? (
                                                <img
                                                    onClick={() => setTogglePassword(!togglePassword)}
                                                    src="img/eye.svg"
                                                    alt="eye"
                                                    className="position-absolute  pwd_icon"
                                                />
                                            ) : (
                                                <img
                                                    onClick={() => setTogglePassword(!togglePassword)}
                                                    src="img/eyeClose.svg"
                                                    alt="eye"
                                                    className="position-absolute  pwd_icon"
                                                />
                                            )}
                                        </div>
                                        {errors.password && <span className="text-danger">{errors.password.message}</span>}
                                    </div>
                                </div>
                            </div>
                            <div className="input_grp mt30">
                                <div className="d-flex align-items-start">
                                    {/* <img src="img/password.svg" alt="password" className="icon_input me-2 mt-1" /> */}
                                    <div className="input_itm w-100">
                                        <label htmlFor="confirmPassword" className="d-block">
                                            Confirm Password
                                        </label>
                                        <div className="position-relative">
                                            <input
                                                type={toggleCPassword ? "" : "password"}
                                                placeholder="Enter confirm password"
                                                className="inputFill border-0"
                                                {...register("confirmPassword", {
                                                    required: "Confirm Password is required",
                                                    validate: (value) => value === watch("password") || "Passwords do not match",
                                                })}
                                            />
                                            {toggleCPassword ? (
                                                <img
                                                    onClick={() => setToggleCPassword(!toggleCPassword)}
                                                    src="img/eye.svg"
                                                    alt="eye"
                                                    className="position-absolute  pwd_icon"
                                                />
                                            ) : (
                                                <img
                                                    onClick={() => setToggleCPassword(!toggleCPassword)}
                                                    src="img/eyeClose.svg"
                                                    alt="eye"
                                                    className="position-absolute  pwd_icon"
                                                />
                                            )}
                                        </div>
                                        {errors.confirmPassword && <span className="text-danger">{errors.confirmPassword.message}</span>}
                                    </div>
                                </div>
                            </div>
                            <div className="footersub">
                                <div className="d-flex text-center mt-5">
                                    <button type="submit" className="btn_cus min150 me-4">
                                        SAVE
                                    </button>
                                    {/* <button onClick={() => reset()} type="submit" className="btn_cus  br_white_btn min150">
                                        Cancel
                                    </button> */}
                                </div>
                            </div>
                        </div>
                    </div>


                </form>
            </div>
        </>
    );
};

export default ChangePassword;
