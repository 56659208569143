import React from "react"
import Dropdown from "react-bootstrap/Dropdown";
import { useNavigate } from "react-router-dom";

const Header = () => {
  const navigate = useNavigate();


  return (
    <>
      <div className="header d-flex align-items-center">

        <div className="end_col ms-auto">

          {localStorage.getItem("user_token") ?
            <Dropdown className="logout-dropdown  d-flex">
              <Dropdown.Toggle id="dropdown-basic" className="bg-transparent border-0 p-0">
                <span className="pointer">
                  <img
                    className="logout"
                    src={
                      "img/logout.png"
                    }
                    alt="profile icon"
                  />


                </span>
              </Dropdown.Toggle>
              {/* <Dropdown.Menu>
                <Dropdown.Item
                  onClick={() => {
                    setIsOpen(true)
                  }}
                  as="button"
                >
                  <img src="img/user.png" alt="login" />
                  <span className="ps-2">My Profile</span>{" "}
                </Dropdown.Item>
                <Dropdown.Item
                  onClick={() => {
                    Axios.get(`logout`)
                      .then((res) => {
                        if (res) {
                          alert("success", "Logged out successfully");
                          resetLocalStorageItems("search", "type", "city", "zip", "jobFilter", "postId")
                          logout();
                        }
                      })
                      .catch((err) => {
                        err.response && console.log("err", err);
                      });
                  }}
                  as="button"
                >
                  <img src="img/Logout.svg" alt="logout" />
                  <span className="ps-2">Logout</span>{" "}
                </Dropdown.Item>
              </Dropdown.Menu> */}
            </Dropdown> :
            <div onClick={() => navigate("/login")} className="loginBnt d-flex align-items-center pointer">
              <img src="img/logginwhite.svg" alt="login" />
              <span className="DMSans_bold fz18 ms-2">Login</span>
            </div>}
        </div>
      </div>
    </>
  );
};

export default Header;
