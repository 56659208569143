import React, { useState } from "react";
import { Button, Drawer } from "antd";

export const CustomDrawer = ({ title, body, onClose, open, type }) => {
  return (
    <Drawer maskClosable className={type === "up" && "up"} closeIcon={false} title={title} onClose={onClose} open={open}>
      {body}
    </Drawer>
  );
};
