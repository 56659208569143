import React, { useEffect, useState } from "react";
import Axios from "../../axios/axiosInstance";
import useDivLoader from "../../components/loaders/useDivLoader";
import useLoader from "../../components/loaders/useLoader";
import { useForm, Controller } from "react-hook-form";
import "primereact/resources/themes/lara-light-cyan/theme.css";
import { MultiSelect } from "primereact/multiselect";
import alert from "../../utils/alert";

export const UserProfile = ({ onClose, type }) => {
    const {
        register,
        handleSubmit,
        control,
        setValue,
        formState: { errors },
    } = useForm({ mode: "onChange" });
    const userId = localStorage.getItem("user_id")
    const [toggleEdit, setToggleEdit] = useState(false)
    const [previewUserImage, setPreviewUserImage] = useState("")
    const [userDetails, setUserDetails] = useState()
    const [singleFile, setSingleFile] = useState()

    const items = [
        { label: "English", value: "English" },
        { label: "Spanish", value: "Spanish" },
        { label: "Russian", value: "Russian" },
        { label: "Chinese", value: "Chinese" },
        { label: "Vietnamese", value: "Vietnamese" },
    ];
    const [apiImages, setApiImages] = useState([]);
    const { showLoader, hideLoader, Loader } = useLoader();

    useEffect(() => {
        !toggleEdit && getData();
    }, [toggleEdit, userId]);

    const getData = async () => {
        showLoader();
        await Axios.get("profile")
            .then((res) => {
                setUserDetails(res.data.data)
                if (res.data.data.profile_pic) {
                    setPreviewUserImage(process.env.REACT_APP_BUILDFOLIO_URL + "media/" + res.data.data.profile_pic)
                }
                setValue("website", res.data.data.website)
                setValue("fullName", res.data.data.name)
                if (res.data.data.job_photos.length > 0) {
                    setApiImages(res.data.data.job_photos)
                }

                if (!res.data.data.language) {
                    setValue("language", ["English"]);
                } else {
                    setValue("language", res.data.data.language.split(","))
                }
            })
            .catch((err) => {
                err.response && alert("error", err.response.data.error);
            })
            .finally(() => {
                hideLoader();
            });
    };

    const onClickSaveChanges = async (data) => {
        showLoader();
        let jobPhotosIds = [];
        let imageId = null;
        if (singleFile) {
            const fileid = await uploadFinalImages(singleFile);
            imageId = fileid[0]

        }
        if (multipleImages.length > 0) {
            const imageid = await uploadFinalImages(multipleImages);
            jobPhotosIds.push(...imageid);
        }

        const payload = {
            ...(data.language && data.language.length > 0 && { language: data.language.join(",") }),
            ...(data.website && { website: data.website }),
            ...(data.fullName && { name: data.fullName }),
            ...(jobPhotosIds.length > 0 && { job_photos: jobPhotosIds }),
            ...(removedUrls.length > 0 && { old_job_photos: removedUrls }),
            ...(imageId && { image: imageId })
        };

        await Axios.put("profile", payload)
            .then((res) => {
                localStorage.setItem("p-img", res.data.image)
                alert("success", res.data.message)
                setToggleEdit(false)
                setMultipleImages([])
                setRemovedUrls([])
            })
            .catch((err) => {
                err.response && alert("error", err.response.data.error);
            })
            .finally(() => {
                hideLoader();
            });
    }

    const uploadUserImage = async (e) => {
        setPreviewUserImage(URL.createObjectURL(e.target.files[0]))
        setSingleFile(e.target.files[0])
    }

    const uploadFinalImages = async (file) => {
        let formData = new FormData()
        if (Array.isArray(file)) {
            file.forEach((ele) => {
                formData.append("file", ele?.file)
            })
        } else {
            formData.append("file", file)
        }

        formData.append("type", "image")

        const res = await Axios.post("media", formData, {
            headers: {
                "Content-Type": "multipart/form-data",
            }
        })
            .catch((err) => {
                err.response && alert("error", err.response.data.error);
            })
            .finally(() => {
                hideLoader();
            });

        return res.data.id;

    }

    const [multipleImages, setMultipleImages] = useState([]);



    const handleImageChange = (e) => {
        const files = e.target.files;
        const updatedImages = [...multipleImages];
        for (let i = 0; i < files.length; i++) {
            updatedImages.push({
                file: files[i],
                preview: URL.createObjectURL(files[i])
            });
        }


        if ((apiImages.length + updatedImages.length) > 5) {
            alert(
                "error", "You can upload a maximum of five photos."
            )
            setMultipleImages([])
            return
        }
        setMultipleImages(updatedImages);
    };

    const handleRemoveImage = (index) => {
        const updatedImages = [...multipleImages];
        updatedImages.splice(index, 1);
        setMultipleImages(updatedImages);
    };
    const [removedUrls, setRemovedUrls] = useState([]);



    const handleRemoveApiImages = (apiImageUrl) => {
        // Remove the image URL from the apiImages state
        const updatedApiImages = apiImages.filter(image => image !== apiImageUrl);
        setApiImages(updatedApiImages);

        // Add the image URL to the removedUrls state
        setRemovedUrls(prevRemovedUrls => [...prevRemovedUrls, apiImageUrl]);
    };


    return (
        <>
            <Loader />
            <div className="d-flex align-items-center justify-content-between mb-4 px-4">
                <button className="p-0 border-0 bg-transparent" onClick={() => {
                    setToggleEdit(false)
                    !toggleEdit && onClose()
                }}>
                    <img src="img/backArrow.svg" alt="backArrow" />
                </button>
                <h1 className="profile_heading mb-0 text-white">{toggleEdit && "Edit"} Profile</h1>
                <button onClick={() => setToggleEdit(!toggleEdit)} className="p-0 border-0 bg-transparent">
                    <img src="img/Edit_icon.svg" alt="Edit_icon" />
                </button>
            </div>


            <>

                <div className="position-relative">
                    {toggleEdit &&

                        <div className="change_img-profile">
                            <input onChange={uploadUserImage} style={{ display: "none" }} id="file" type="file" />
                            <label for="file">{!userDetails?.profile_pic ? "Upload" : "Change"} Image</label>
                        </div>
                    }
                    <img src={previewUserImage ? previewUserImage : "img/avatar.png"} alt="profile-img" className="w-100 profile_main-img" />
                    {!toggleEdit &&
                        <div className="p-4 d-flex justify-content-between align-items-center profile_ontxt">
                            <div>
                                <div className="d-flex align-items center text-white fz18 DMSans_bold">{userDetails?.fullName} {userDetails?.status === "verified" && <img src="img/checkBlue.svg" alt="checkBlue" className="ms-2" />} </div>
                                <div className="d-flex align-items center text-white fz16 Outfit_reg"><img src="img/call_white_icon.svg" alt="phone" className="icon_input me-2 mt-1" />{userDetails?.phone_number}</div>
                            </div>
                            {userDetails?.website &&
                                <a target="_blank" href={userDetails?.website} style={{ textDecoration: "none" }} className="viewWebsite">View website</a>}
                        </div>}
                </div>
            </>

            <form className="form p-4 graycr" onSubmit={handleSubmit(onClickSaveChanges)}>
                {!toggleEdit ?
                    <>
                        <div className="whiteBox">
                            <div className="bor_dash_btm mb-4">
                                <h2 className="fz18 DMSans_bold mb-0">{userDetails?.businessname}</h2>
                            </div>

                            <div className="output_dta bor_dash_btm">
                                <label>email</label>
                                <p className="mb-0 text-dark DMSans_med">{userDetails?.email}</p>
                            </div>



                            <div className="output_dta mt-4">
                                <label>Languages</label>
                                <div className="output_dta-itms d-flex align-items-center flex-wrap">
                                    {userDetails?.language?.split(",").map((ele) => {
                                        return <span className="lagbox d-block">{ele}</span>
                                    })}
                                </div>

                            </div>
                        </div>

                    </>
                    :
                    <>
                        <div className="input_grp ">
                            <div className="d-flex align-items-start">
                                <img src="img/username.svg" alt="phone" className="icon_input me-2 mt-1" />
                                <div className="input_itm w-100">
                                    <label htmlFor="phone" className="d-block">
                                        Full Name
                                    </label>
                                    <input
                                        type="text"
                                        id="phone"
                                        className="inputFill border-0"
                                        placeholder="Enter Full Name"
                                        maxLength={50}
                                        {...register("fullName", {
                                            required: "Full Name is required",
                                        })}
                                    />
                                </div>
                            </div>
                        </div>
                        {errors.fullName && <span className="text-danger">{errors.fullName.message}</span>}
                        <div className="input_grp mt30">
                            <div className="d-flex align-items-start">
                                <img src="img/webIcon.svg" alt="phone" className="icon_input me-2 mt-1" />
                                <div className="input_itm w-100">
                                    <label htmlFor="phone" className="d-block">
                                        Website
                                    </label>
                                    <input
                                        className="inputFill border-0"
                                        placeholder="https://www.yourwebsite.com"
                                        {...register("website", {
                                            // required: "Website is required",
                                            pattern: {
                                                value:
                                                    /^(ftp|https?):\/\/[a-zA-Z0-9-]+(\.[a-zA-Z0-9-]+)+(\.[a-zA-Z]{2,})?(:[0-9]{1,5})?(\/.*)?$/,
                                                message: "Please enter a valid website URL",
                                            },
                                        })}
                                        type="url"
                                    />
                                </div>
                            </div>
                            {errors.website && <span className="text-danger">{errors.website.message}</span>}
                        </div>
                        <div className="input_grp mt-4">
                            <div className="input_itm w-100 position-relative">
                                <label className="d-block">
                                    Do you have language preference? <span style={{ color: "red" }}>*</span>
                                </label>
                                <div>
                                    <Controller
                                        name="language"
                                        control={control}
                                        rules={{ required: "Language is required." }}
                                        render={({ field }) => (
                                            <MultiSelect
                                                display="chip"
                                                className="custom-multiselect inputFill border-0 bg-white"
                                                id="language"
                                                name="language"
                                                value={field.value || []}
                                                options={items}
                                                onChange={(e) => field.onChange(e.value)}
                                                optionLabel="label"
                                                placeholder="Select Language"
                                            // maxSelectedLabels={3}
                                            />
                                        )}
                                    />
                                </div>
                            </div>
                            {errors.language && <span className="text-danger">{errors.language.message}</span>}
                        </div>
                    </>}

                <div className="job-container whiteBox mt-4">
                    <h5 className="fz18 text-dark mb-2 DMSans_bold">job photos</h5>
                    <div>
                        <>

                            <div className="upload-img-icon">
                                <div className="grid3">
                                    {toggleEdit &&
                                        <div className="inputBox  mt-3">
                                            <input
                                                type='file'
                                                onChange={handleImageChange}
                                                multiple
                                                id="uploadinput"
                                                className="d-none"
                                                accept=".jpg, .jpeg, .png"
                                            />
                                            <label htmlFor="uploadinput" className="uploadinput d-flex align-items-center flex-column justify-content-center">
                                                <img src="img/upload_img.svg" alt="icon" />
                                                <span className="d-bock fz14">Upload</span>
                                            </label>
                                        </div>}
                                    <>
                                        {apiImages.map((apiImage, index) => (
                                            <div key={`api-${index}`} className="uploaded-img position-relative  mt-3">
                                                <img
                                                    src={process.env.REACT_APP_BUILDFOLIO_URL + "media/" + apiImage} // Assuming the API response has a URL field
                                                    alt={`API Image ${index + 1}`}
                                                    className="uploaded-img-view"
                                                />
                                                {toggleEdit &&
                                                    <button onClick={(e) => {
                                                        e.preventDefault()
                                                        handleRemoveApiImages(apiImage)
                                                    }} className="btndlt-img-upl">
                                                        <img src="img/delete_img.svg" alt="delete_img" />
                                                    </button>}
                                            </div>
                                        ))}
                                        {multipleImages.map((image, index) => (
                                            <div key={index} className="uploaded-img position-relative me-3 mt-3">
                                                <img
                                                    src={image.preview}
                                                    alt={`Preview ${index + 1}`}
                                                    className="uploaded-img-view"
                                                />
                                                {toggleEdit &&
                                                    <button onClick={() => handleRemoveImage(index)} className="btndlt-img-upl">
                                                        <img src="img/delete_img.svg" alt="delete_img" />
                                                    </button>}
                                            </div>
                                        ))}
                                    </>
                                </div>

                            </div>
                        </>

                    </div>
                </div>

                {toggleEdit && <button type="submit" className="btn_cus mt30" >save changes</button>}
            </form >
        </>
    );
};
