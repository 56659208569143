import React, { useState } from "react";
import { PacmanLoader, ClockLoader } from "react-spinners";
import { RotatingTriangles, Discuss, Oval } from "react-loader-spinner";
const useLoader = () => {
  const [isLoading, setIsLoading] = useState(false);

  const showLoader = () => {
    setIsLoading(true);
  };

  const hideLoader = () => {
    setIsLoading(false);
  };

  const Loader = ({ type = "Puff", color = "#045C46", height = 100, width = 200 }) => {
    return isLoading ? (
      <div className="loader-overlay">
        <div className="loader-container">
          {/* <PacmanLoader color="black" /> */}
          {/* <ClockLoader color="black"  /> */}
          {/* <RotatingTriangles
            visible={true}
            height={height}
            width={width}
            colors={["#000000", "#000000", "#000000"]}
            ariaLabel="rotating-triangles-loading"
            wrapperStyle={{}}
            wrapperClass=""
          /> */}
          {/* <Discuss
            visible={true}
            height="80"
            width="80"
            ariaLabel="discuss-loading"
            wrapperStyle={{}}
            wrapperClass="discuss-wrapper"
            colors={["#282828", "#000000"]}
            backgroundColor="#F4442E"
          /> */}
          <Oval
            strokeWidth="2.5"
            visible={true}
            height="60"
            width="60"
            color="#000000"
            secondaryColor="grey"
            ariaLabel="oval-loading"
            wrapperStyle={{}}
            wrapperClass=""
          />
        </div>
      </div>
    ) : null;
  };

  return { showLoader, hideLoader, Loader };
};

export default useLoader;
