// export function formatDate(inputDateString, type) {
//   const inputDate = new Date(inputDateString);

//   if (type === "date-time") {
//     const options = {
//       year: "numeric",
//       month: "2-digit",
//       day: "2-digit",
//       hour: "numeric",
//       minute: "numeric",
//       second: "numeric",
//     };
//     return inputDate.toLocaleDateString("en-US", options).replace(/\//g, "-");
//   } else {
//     const options = { year: "numeric", month: "2-digit", day: "2-digit" };
//     return inputDate.toLocaleDateString("en-US", options).replace(/\//g, "/");
//   }
// }

export function formatDate(inputDateString, type) {
  const inputDate = new Date(inputDateString);

  if (type === "date-time") {
    const dateOptions = {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
    };
    const timeOptions = {
      hour: "2-digit",
      minute: "2-digit",
      hour12: false,
    };

    const datePart = inputDate.toLocaleDateString("en-US", dateOptions).replace(/\//g, "-");
    const timePart = inputDate.toLocaleTimeString("en-US", timeOptions);

    return `${datePart} ${timePart}`;
  } else {
    const options = { year: "numeric", month: "2-digit", day: "2-digit" };
    return inputDate.toLocaleDateString("en-US", options).replace(/\//g, "/");
  }
}



