import React, { useState } from "react";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import { logout } from "../../utils/logout";
import alert from "../../utils/alert";
import Axios from "../../axios/axiosInstance";
import CustomModal from "../common/CustomModal";
import { resetLocalStorageItems } from "../../utils/resetLocalStorageItems";

const Sidebar = () => {
  const navigate = useNavigate()
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const status = queryParams.get("status") || ""

  //modal hooks
  const [show, setShow] = useState(false); //show and hide modal
  const [modalData, setModalData] = useState({ typeOfModal: "", reportType: "", text: {} }) //modal selection based on parameters

  const resetFilter = () => {
    setModalData({ typeOfModal: "", reportType: "", text: {} })
    // setContractorDetails("");
    // setSharedData((prevData) => ({
    //   ...prevData,
    //   search: "",
    //   type: "",
    //   city: "",
    //   zip: "",
    //   jobFilter: "",
    //   postId: "",
    // }));
    // resetLocalStorageItems("search", "type", "city", "zip", "jobFilter", "postId")
  };

  return (
    <>
      <div className="sidebar">
        <h1 className="text-center logo_dash mobile_12none">
          <img src="img/logo.svg" alt="logo" />
        </h1>
        <ul className={`sidebar_grp ps-0 "loginside"}`}>
          <li onClick={() => resetFilter()}>
            <NavLink
              to={"/users"}
              className={"d-flex align-items-center"
              }
            >
              <span>
                <img src="img/asideuser.svg" alt="asideuser" />
              </span>
              <span className="ms-2">Users List</span>
            </NavLink>
          </li>
          <li>
            <NavLink onClick={() => resetFilter()} to={"/license"} className="d-flex align-items-center">
              <span>
                <img src="img/asidecontractors.svg" alt="asidecontractors" />
              </span>
              <span className="ms-2">Active Contractors List</span>
            </NavLink>
            <ul className="sider_lts_inn-grp">
              <li onClick={() => {
                navigate("/license")
                setModalData({ typeOfModal: "upload", reportType: "license", text: {} }) //upload modal selected based on typeofmodal 
                setShow(true)
              }} className="sider_lts_inn-itm">
                <div style={{ color: show && modalData.reportType === "license" && "#006A8E" }} className="pointer">Upload New</div>
              </li>
            </ul>
          </li>
          <li>
            <NavLink onClick={() => resetFilter()} to={"/construction-permit"} className="d-flex align-items-center">
              <span>
                <img src="img/constructionpermits.svg" alt="constructionpermits" />
              </span>
              <span className="ms-2">Construction Permit</span>
            </NavLink>
            <ul className="sider_lts_inn-grp">
              <li onClick={() => navigate("/construction-permit/permit-list?status=intake-ready")} className="sider_lts_inn-itm">
                <div style={{ color: status === "intake-ready" && "#006A8E" }} className="pointer">Intake Ready</div>
              </li>
              <li onClick={() => navigate("/construction-permit/permit-list?status=issued")} className="sider_lts_inn-itm">
                <div style={{ color: status === "issued" && "#006A8E" }} className="pointer">Issued</div>
              </li>
              <li onClick={() => {
                navigate("/construction-permit")
                setModalData({ typeOfModal: "upload", reportType: "permits", text: {} })
                setShow(true)
              }} className="sider_lts_inn-itm">
                <div style={{ color: show && modalData.reportType === "permits" && "#006A8E" }} className="pointer">Upload New</div>
              </li>
            </ul>
          </li>
          <li onClick={() => resetFilter()}>
            <NavLink to={"/change-password"} className="d-flex align-items-center">
              <span>
                <img src="img/aside_password.svg" alt="password" />
              </span>
              <span className="ms-2">Change Password</span>
            </NavLink>
          </li>
        </ul>

        {/* logout btn will be visible when user is authenticated */}
        {localStorage.getItem("user_token") &&
          <div onClick={() => {
            Axios.get(`logout`)
              .then((res) => {
                if (res) {
                  alert("success", "Logged out successfully");
                  resetLocalStorageItems("search", "type", "city", "zip", "jobFilter", "postId")
                  logout();
                }
              })
              .catch((err) => {
                err.response && console.log("err", err);
              });
          }} className="logout_btn">
            <img src="img/Logout.svg" alt="Logout" className="me-2" />
            Logout
          </div>}
      </div>


      <CustomModal
        modalData={modalData}
        show={show}
        onHide={() => {
          setShow(false);
        }}
        handleClose={() => {
          setShow(false);
        }}
      />
    </>
  );
};

export default Sidebar;
