import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import Axios from "../../axios/axiosInstance";
import alert from "../../utils/alert";
import { Button } from "antd";
import { JOB_POST_QUERY_PARAMS } from "../../config/queryParams";

const Login = () => {
  const {
    register,
    setValue,
    handleSubmit,
    formState: { errors },
  } = useForm({ mode: "onChange" });

  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const description = queryParams.get("description") || "";

  const [loading, setLoading] = useState(false);
  const [view, setView] = useState(false);
  const [rememberMe, setRememberMe] = useState(false);

  useEffect(() => {
    const rememberMeChecked = localStorage.getItem("remember_me") === "true";
    if (rememberMeChecked) {
      const userEmail = localStorage.getItem("user_email") || "";
      // Set the email and remember me status when the component mounts
      setRememberMe(true);
      setValue("email", userEmail);
    }
  }, []);

  const onSubmit = (data) => {
    // Handle form submission
    setLoading(true);
    console.log(data);
    Axios.post("login", {
      email: data.email,
      password: data.password,
      role: "admin"
    })
      .then((res) => {
        if (res) {
          localStorage.setItem("p-img", res.data.image)
          localStorage.setItem("user_token", res.data.token);
          localStorage.setItem("user_id", res.data.id);
          if (rememberMe) {
            localStorage.setItem("remember_me", true);
            localStorage.setItem("user_email", data.email);
          } else {
            localStorage.setItem("remember_me", false);
            localStorage.removeItem("remember_me");
            localStorage.removeItem("user_email");
          }
          alert("success", "Logged in successfully");
          setTimeout(() => {
            setLoading(false);
            navigate("/users")
          }, 1200);
        }
      })
      .catch((err) => err.response && alert("error", err?.response?.data?.error))
      .finally(() => {
        setTimeout(() => {
          setLoading(false);
        }, 3000);
      });
  };

  return (
    <>
      <h3 className="boldtxt_on text-center">Login</h3>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="min_hform">
          <div className="input_grp">
            <div className="d-flex align-items-start">
              <img src="img/email.svg" alt="email" className="icon_input me-2 mt-1" />
              <div className="input_itm w-100">
                <label htmlFor="email" className="d-block">
                  Email Address
                </label>
                <input
                  type="email"
                  id="email"
                  className="inputFill border-0"
                  placeholder="Enter Email Address"
                  {...register("email", {
                    required: "Email is required",
                    pattern: {
                      value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                      message: "Please enter a valid email address",
                    },
                  })}
                />
              </div>
            </div>
          </div>

          {errors.email && <span className="text-danger">Please enter a valid email address</span>}
          <div className="input_grp mt30">
            <div className="d-flex align-items-start">
              <img src="img/password.svg" alt="password" className="icon_input me-2 mt-1" />
              <div className="input_itm w-100">
                <label htmlFor="password" className="d-block">
                  Password
                </label>
                <div className="position-relative">
                  <input
                    type={view ? "" : "password"}
                    id="password"
                    className="inputFill border-0"
                    placeholder="Enter Password"
                    {...register("password", {
                      required: "Password is required",
                      validate: (value) =>
                        value.trim().length >= 8 || "Password must be at least 8 characters long",
                    })}
                  />
                  {view ? (
                    <img
                      onClick={() => setView(false)}
                      src="img/eye.svg"
                      alt="eye"
                      className="position-absolute  pwd_icon"
                    />
                  ) : (
                    <img
                      onClick={() => setView(true)}
                      src="img/eyeClose.svg"
                      alt="eye"
                      className="position-absolute  pwd_icon"
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
          {errors.password && <span className="text-danger">Please enter your password</span>}

          <div className="d-flex align-items-center justify-content-between mt-2">
            <div className="RemMe d-flex align-items-center mt-2">
              <input
                type="checkbox"
                id="RemMe"
                className="pointer"
                checked={rememberMe}
                onChange={(e) => setRememberMe(e.target.checked)}
              />

              <label htmlFor="RemMe" className="ms-2 pointer">
                {" "}
                Remember me
              </label>
            </div>
            {/* <Link className="fz14 black text-decoration-none" to={`/forgot-password`}> Forgot password?</Link> */}
          </div>
        </div>
        {loading ? (
          <Button
            style={{ padding: "20px", height: "fit-content" }}
            id="b"
            type="submit"
            className="btn_cus"
            loading={loading}
          >
            Login
          </Button>
        ) : (
          <button id="b" type="submit" className="btn_cus">
            LOGIN
          </button>
        )}

        {/* <p className="text-center mt-3">
          Don’t have an account?{" "}
          <Link
            to={`/forgot-password`}
            className="black me-1"
          >
            Sign up
          </Link>
        </p> */}
      </form>
    </>
  );
};

export default Login;
