import React, { useEffect, useState } from "react";
import Axios from "../../axios/axiosInstance";
import { formatDate } from "../../utils/dateConverter";
import useDivLoader from "../../components/loaders/useDivLoader";
import { useAppContext } from "../../context/SharedData";
import MyPagination from "../../components/Pagination/MyPagination";


const LicenseList = () => {
  const [licenseData, setLicenseData] = useState()
  const { setPageRefresh, pageRefresh } = useAppContext();
  const { showLoader, hideLoader, Loader, isLoading } = useDivLoader();
  const [currentPage, setCurrentPage] = useState(1)

  useEffect(() => {
    getLicenseLists()
  }, [pageRefresh, currentPage])

  const getLicenseLists = () => {
    showLoader()
    Axios.get(`/license/files?page=${currentPage}&limit=${10}`).then((res) => {
      setLicenseData(res.data)

    }).catch((err) => {
      alert("error", err)
    })
      .finally(() => {
        hideLoader()
        setPageRefresh(false)
      })
  }

  return (
    <>
      <div className="d-flex align-items-center justify-content-between">
        <h4 className="onbord_heading boldtxt_on">Active Contractors List</h4>
      </div>
      <div className="whitebox-container mt-4">
        <div className={`tbwrp  mt-3 `}>
          <div className="page d-flex align-items-center text-nowrap d-xl-none">
          </div>
          <table>
            <thead>
              <tr>
                <th width="12%">Serial Number</th>
                <th width="15%"> Name</th>
                <th width="15%">Status</th>
                <th width="15%">Created At</th>

              </tr>
            </thead>
            <tbody className="ldr">
              {licenseData?.data?.length > 0 && licenseData?.data?.map((ele, index) => {
                return <tr key={index}>
                  <td>{currentPage === 1 ? index + 1 : currentPage * 10 + (index + 1) - 10}</td>
                  <td>{ele.name}</td>
                  <td>{ele.status === "completed" ? <span style={{ color: "green" }}>{ele.status}</span> : <span style={{ color: "orange" }}>{ele.status}</span>}</td>
                  <td>{formatDate(ele.created_at, "date-time")}</td>
                </tr>
              })}

              <Loader type={"tableLines"} />
            </tbody>
            {!isLoading && licenseData?.data?.length === 0 && (
              <p className="mt-4" style={{ textAlign: "center" }}>
                No data found
              </p>
            )}
          </table>
          <div className="d-flex align-items-center justify-content-between mt-4">
            <div className="page d-xl-flex align-items-center text-nowrap d-none">
              {licenseData?.totalRecords > 0 && (
                <span>
                  Showing {licenseData?.totalRecords} {licenseData?.totalRecords === 1 ? 'entry' : 'entries'}
                </span>
              )}
            </div>
            {!isLoading && licenseData?.data?.length > 0 && (
              <div className="paggtbl ms-auto">
                <MyPagination
                  key={currentPage}
                  onChange={(e) => setCurrentPage(e)}
                  current={currentPage} s
                  total={licenseData?.totalRecords}
                  limit={10}
                />
              </div>
            )}
          </div>

        </div>
      </div></>
  );
};

export default LicenseList;
