import React from "react";
import { Pagination } from "antd";

const MyPagination = ({ current, total, onChange, limit }) => (
  <Pagination
    pageSize={limit ? limit : 10}
    defaultCurrent={Number(current)}
    total={Number(total)}
    onChange={onChange}
  />
); 

export default MyPagination;
