import React, { createContext, useContext, useState } from "react";

const AppContext = createContext();

export const useAppContext = () => {
  return useContext(AppContext);
};

export const AppProvider = ({ children }) => {
  const [pageRefresh, setPageRefresh] = useState(false);
  // Define the state and any functions you want to share here

  const [sharedData, setSharedData] = useState({
    emailForOtpVerify: null  // it is used to verify email in otp verify case //code not deployed 
  });

  return (
    <AppContext.Provider
      value={{
        sharedData,
        pageRefresh,
        setPageRefresh,
        setSharedData, //common data in all the panels
      }}
    >
      {children}
    </AppContext.Provider>
  );
};
